<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>Generate Invoice Data</strong>
            </div>
            <b-row>
              <!--building selection-->
              <BuildingDropdown :server-params="form"
                                :form-group="true"
                                :on-select="getAccounts" />
              <b-col sm="12">
                <form-group :validator="$v.form.invoiceDate"
                            label="Invoice Date"
                            :use-horizontal="false">
                  <b-form-input type="date"
                                placeholder="Enter invoice date"
                                autocomplete="off"
                                v-model="form.invoiceDate"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.period"
                            label="Period"
                            :use-horizontal="false">
                  <b-form-input type="month"
                                placeholder="Enter period"
                                autocomplete="off"
                                v-model="form.period"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.accounts"
                            label="Choose Accounts"
                            :use-horizontal="false">
                  <treeselect v-model="form.accounts"
                              placeholder="Select Accounts"
                              :multiple="true"
                              :options="options.account" />
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                Generate
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    data: () => ({
      form: {
        invoiceDate: null,
        period: null,
        accounts: null,
      },
      options: {
        account: [],
      },
    }),
    validations: {
      form: {
        invoiceDate: { required },
        period: { required },
        accounts: { required },
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      this.getAccounts();
    },
    methods: {
      getAccounts() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/account",
            params: {
              buildingId: self.form.buildingId,
            }
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.account = [
                {
                  id: "all",
                  label: "Select All",
                  children: response.data.data.map((x) => ({
                    id: x.accountId,
                    label: `${x.accountId}# ${x.name}`,
                  })),
                },
              ];
            }
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText =
          "You are about to generate invoice data. Are you sure ?",
          _okText = "Yes, Generate",
          _action = "apis/post",
          _url = "/transaction/invoice/generate";

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
